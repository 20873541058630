import "./App.css";

function App() {
  return (
    <div className="soon">
      <h2>Huh!, come back later.</h2>
    </div>
  );
}

export default App;
